import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/pages/Home.vue";
import SobrePage from "@/pages/Sobre.vue";
import DiretoriaPage from '@/pages/Diretoria.vue';
import DownloadsPage from '@/pages/Downloads.vue';
import PessoaFisicaPage from '@/pages/PessoaFisica.vue';
import PessoaJuridicaPage from '@/pages/PessoaJuridica.vue';
import LegislacaoPage from '@/pages/Legislacao.vue';
import NoticiasPage from '@/pages/Noticias.vue';
import ContatoPage from '@/pages/Contato.vue';
import ConselheirosPage from '@/pages/Conselheiros.vue';
import OrnogramaPage from '@/pages/Ornograma.vue';
import Eleicoes2018Page from '@/pages/Eleicoes2018.vue';
import Eleicoes2021Page from '@/pages/Eleicoes2021.vue';
import Eleicoes2024Page from "@/pages/Eleicoes2024.vue";
import ContasPage from '@/pages/PrestacaoDeContas.vue';
import ComissoesPage from '@/pages/Comissoes.vue';
import ImportanciaFiscalizacaoPage from '@/pages/ImportanciaFiscalizacao.vue';
import CompeticoesPage from '@/pages/FiscalizacaoCompeticoes.vue';
import BeneficiosPage from '@/pages/BeneficiosFiscalizacao.vue';
import OrientacoesPage from '@/pages/Orientacoes.vue';
import RelatoriosPage from '@/pages/Relatorios.vue';
import FiscalizaFisicaPage from '@/pages/FiscalizacaoPessoaFisica.vue';
import FiscalizaJuridicaPage from '@/pages/FiscalizacaoPessoaJuridica.vue';
import FiscalizaEstagioPage from '@/pages/FiscalizacaoEstagiarios.vue';
import NoticiaLeitura from '@/pages/NoticiaLeitura.vue';
import MultimidiaLeitura from '@/pages/MultimidiaLeitura.vue'
import NaoEncontrada from '@/pages/NaoEncontrada.vue';
import LeituraPf from '@/pages/LeituraPessoafisica.vue';
import LeituraPj from '@/pages/LeituraPessoajuridica.vue';
import LeituraVideos from '@/pages/LeituraVideos.vue';
import EleicoesPage from '@/pages/Eleicoes.vue';
import PrimeiroRegistro from '@/pages/PrimeiroRegistro.vue';
import BoletoAnuidadeVue from '@/pages/BoletoAnuidade.vue';
import BoletinsPage from '@/pages/Boletins.vue';
import PolticaDePrivacidadeVue from '@/pages/PoliticaDePrivacidade.vue';

const routes = [

  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/sobre",
    name: "Sobre",
    component: SobrePage,
  },
  {
    path: "/primeiro-registro",
    name: "Primeiro Registro",
    component: PrimeiroRegistro,
  },
  {
    path: "/politica-de-privacidade",
    name: "Política de Privacidade",
    component: PolticaDePrivacidadeVue,
  },
  {
    path: "/boleto-anuidade",
    name: "Boleto Anuidade",
    component: BoletoAnuidadeVue,
  },
  {
    path: "/diretoria",
    name: "Diretoria",
    component: DiretoriaPage,
  },
  {
    path: "/downloads",
    name: "Downloads",
    component: DownloadsPage,
  },
  {
    path: "/pessoa-fisica",
    name: "Pessoa Física",
    component: PessoaFisicaPage,
  },
  {
    path: "/pessoa-juridica",
    name: "Pessoa Jurídica",
    component: PessoaJuridicaPage,
  },
  {
    path: "/legislacao",
    name: "Legislação",
    component: LegislacaoPage,
  },
  {
    path: '/noticias',
    name: 'Notícias',
    component: NoticiasPage
  },
  {
    path: '/noticia/:noticia',
    name: 'Leitura Notícia',
    component: NoticiaLeitura
  },
  {
    path: '/multimidia/:multimidia',
    name: 'Leitura Multimídia',
    component: MultimidiaLeitura
  },
  {
    path: '/contato',
    name: 'Contato',
    component: ContatoPage
  },
  {
    path: '/conselheiros',
    name: 'Conselheiros',
    component: ConselheirosPage
  },
  {
    path: '/ornograma',
    name: 'Ornograma',
    component: OrnogramaPage
  },
  {
    path: '/eleicoes-2018',
    name: 'Eleições 2018',
    component: Eleicoes2018Page
  },
  {
    path: '/eleicoes-2021',
    name: 'Eleições 2021',
    component: Eleicoes2021Page
  },
  {
    path: '/eleicoes-2024',
    name: 'Eleições 2024',
    component: Eleicoes2024Page
  },
  {
    path: '/prestacao-de-contas',
    name: 'Prestação de Contas',
    component: ContasPage
  },
  {
    path: '/comissoes',
    name: 'Comissões',
    component: ComissoesPage
  },
  {
    path: '/importancia-da-fiscalizacao',
    name: 'Importância da Fiscalização',
    component: ImportanciaFiscalizacaoPage
  },
  {
    path: '/competicoes-e-jogos',
    name: 'Competições e Jogos',
    component: CompeticoesPage
  },
  {
    path: '/beneficios-da-fiscalizacao',
    name: 'Benefícios da Fiscalização',
    component: BeneficiosPage
  },
  {
    path: '/orientacoes',
    name: 'Orientações',
    component: OrientacoesPage
  },
  {
    path: '/relatorios',
    name: 'Relatórios',
    component: RelatoriosPage
  },
  {
    path: '/fiscalizacao-pessoa-fisica',
    name: 'Fiscalização Pessoa Física',
    component: FiscalizaFisicaPage
  },
  {
    path: '/fiscalizacao-pessoa-juridica',
    name: 'Fiscalização Pessoa Jurídica',
    component: FiscalizaJuridicaPage
  },
  {
    path: '/fiscalizacao-estagiarios',
    name: 'Fiscalização Estagiários',
    component: FiscalizaEstagioPage
  },
  {
    path: '/pf/:link',
    name: 'Leitura Pessoa Física',
    component: LeituraPf
  },
  {
    path: '/pj/:link',
    name: 'Leitura Pessoa Jurídica',
    component: LeituraPj
  },
  {
    path: '/video/:video',
    name: 'Leitura Vídeos',
    component: LeituraVideos
  },
  {
    path: '/eleicoes',
    name: 'Eleiçoes',
    component: EleicoesPage
  },
  {
    path: '/boletins',
    name: 'Boletins',
    component: BoletinsPage
  },
  {
    path: "/:pathMatch(.)",
    name: "Não Encontrada",
    component: NaoEncontrada,
    meta: {
      requireAuth: false,
      title: "Página Não Encontrada"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;