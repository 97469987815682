<template>
    <section class="breadcrumb-topo" :style="{backgroundImage: `url(${backgroundBread})`}">
        <div class="container">
            <div class="row">
                <div class="map">
                    <span v-if="currentBread">
                        HOME {{currentBread.label}} - <span class="a-pagina">{{ currentBread.subtitulo }}</span>
                    </span>
                </div>
                <div class="titulo-da-pagina" v-if="currentBread">
                    <h2>
                        {{ currentBread.titulo }}
                    </h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import Vue from 'vue';
import { useRoute } from 'vue-router';

export default {
    name: 'BreadcrumbVue',
    setup() {
        const route = useRoute;
        console.log(route);
    },
    data() {
        return {
            backgroundBread: require('@/assets/imgs/BG-Topo.png'),

            breadcrumbs: [
                {label: '- INSTITUCIONAL', titulo: 'Sobre o CREF20/SE', subtitulo: 'Nossa História' ,url: '/sobre'},
                {label: '- INSTITUCIONAL', titulo: 'Diretoria - CREF20/SE', subtitulo: 'Diretoria', url: '/diretoria'},
                {label: '- INSTITUCIONAL', titulo: 'DOWNLOADS - CREF20/SE', subtitulo: 'Downloads', url: '/downloads'},
                {label: '- INSTITUCIONAL', titulo: 'CONSELHEIROS - CREF20/SE', subtitulo: 'Conselheiros', url: '/conselheiros'},
                {label: '- INSTITUCIONAL', titulo: 'ORNOGRAMA - CREF20/SE', subtitulo: 'Ornograma', url: '/ornograma'},
                {label: '- INSTITUCIONAL', titulo: 'ELEIÇÕES 2018 - CREF20/SE', subtitulo: 'Eleições 2018', url: '/eleicoes-2018'},
                {label: '- INSTITUCIONAL', titulo: 'ELEIÇÕES - CREF20/SE', subtitulo: 'Eleições', url: '/eleicoes'},
                {label: '- INSTITUCIONAL', titulo: 'ELEIÇÕES 2021 - CREF20/SE', subtitulo: 'Eleições 2021', url: '/eleicoes-2021'},
                {label: '- INSTITUCIONAL', titulo: 'ELEIÇÕES 2024 - CREF20/SE', subtitulo: 'Eleições 2024', url: '/eleicoes-2024'},
                {label: '- INSTITUCIONAL', titulo: 'COMISSÕES - CREF20/SE', subtitulo: 'Comissões 2021', url: '/comissoes'},
                {label: '- INSTITUCIONAL', titulo: 'PRESTAÇÃO DE CONTAS - CREF20/SE', subtitulo: 'Prestação de contas', url: '/prestacao-de-contas'},
                {label: '- SERVIÇOS', titulo: 'Registro Pessoa Física', subtitulo: 'Pessoa Física' , url: '/pessoa-fisica'},
                {label: '- SERVIÇOS', titulo: 'Registro Pessoa Jurídica', subtitulo: 'Pessoa Jurídica' , url: '/pessoa-juridica'},
                {label: '- FISCALIZAÇÃO', titulo: 'importância da Fiscalização', subtitulo: 'importância da Fiscalização' , url: '/importancia-da-fiscalizacao'},
                {label: '- FISCALIZAÇÃO', titulo: 'Competições e Jogos', subtitulo: 'Competições e Jogos' , url: '/competicoes-e-jogos'},
                {label: '- FISCALIZAÇÃO', titulo: 'Benefícios da fiscalização', subtitulo: 'Benefícios da fiscalização' , url: '/beneficios-da-fiscalizacao'},
                {label: '- FISCALIZAÇÃO', titulo: 'Departamento de Orientações e Fiscalização', subtitulo: 'Orientações e Fiscalização' , url: '/orientacoes'},
                {label: '- FISCALIZAÇÃO', titulo: 'Relatórios do CREF20/SE', subtitulo: 'Relatórios' , url: '/relatorios'},
                {label: '- FISCALIZAÇÃO', titulo: 'Fiscalização de Pessoa Física', subtitulo: 'Pessoa Física' , url: '/fiscalizacao-pessoa-fisica'},
                {label: '- FISCALIZAÇÃO', titulo: 'Fiscalização de Pessoa Jurídica', subtitulo: 'Pessoa Jurídica' , url: '/fiscalizacao-pessoa-juridica'},
                {label: '- FISCALIZAÇÃO', titulo: 'Fiscalização de Estagiários', subtitulo: 'Estagiários' , url: '/fiscalizacao-estagiarios'},
                {label: '- LEGISLAÇÃO', titulo: 'Leis', subtitulo: 'Leis' , url: '/legislacao'},
                {label: null, titulo: 'Fale Conosco', subtitulo: 'contato', url: '/contato'},
                {label: null, titulo: 'Boletins', subtitulo: 'boletins', url: '/boletins'},
            ],
            currentBread: null 
        }
    },
    methods: {
        updateBreadcrumb() {
            const currentURL = this.$route.path;
            this.currentBread = this.breadcrumbs.find(breadcrumb => breadcrumb.url === currentURL);
        }
    },
    created() {
        this.updateBreadcrumb();
    }
}
</script>

<style scoped>
.titulo-da-pagina {
    text-transform: uppercase;
}
.breadcrumb-topo {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 408px;
}

.breadcrumb-topo .container {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    height: 330px;
}

.breadcrumb-topo .map span {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;    
}
.breadcrumb-topo .map span .a-pagina {
    color: #45A047;
}
.breadcrumb-topo .titulo-da-pagina h2 {
    color: #FFF;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 765px) {
    .breadcrumb-topo .titulo-da-pagina h2 {
        font-size: 44px;
    }
    .breadcrumb-topo {
        height: 308px;
    }

}
</style>