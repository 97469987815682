<template>
    <section class="sobre-home mt-5">
    <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="216" viewBox="0 0 1366 216" fill="none" preserveAspectRatio="none">
        <path d="M1366.95 0.425781H-0.179932V15.616C443.845 276.188 887.87 287.873 1366.95 15.616V0.425781Z" fill="white"/>
      </svg>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6" v-for="(sobre, index) in sobreConteudos" :key="index">
                <img :src="sobre.imagem_sobre" alt="Sobre Home" class="person-sobre">
            </div>

            <div class="col-lg-6">
                <div class="info-sb-home">
                    <div class="titulo-sobre" v-for="(sobre, index) in sobreConteudos" :key="index">
                        <h3>
                            {{ sobre.titulo_sobre }}
                        </h3>
                    </div>

                    <ul class="lista-principios mt-5">
                        <li v-for="(principio, index) in principios" :key="index">
                            <span class="titulo-principio">
                                {{ principio.titulo_principio }}
                            </span> <br>
                            <span class="subtitulo-principios">
                                {{ principio.subtitulo_principio }}
                            </span>
                        </li>
                    </ul>

                    <div class="btn-sobre">
                        <router-link to="/sobre" aria-label="sobre">
                            Sobre o CREF20/SE
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="info-sb-home">
                    <div class="titulo-sobre">
                        <h3>Envie sua proposta de projeto aqui</h3>
                    </div>
                    <form enctype="multipart/form-data"   @submit.prevent="sendEmail" id="formProposta">
                        <div class="row">
                            <div class="col-lg-12">
                                <input type="text" required class="form-control" id="nome" placeholder="Nome" v-model="nome">
                            </div>
                            <div class="col-lg-12">
                                <input type="text" required class="form-control" name="endereco" id="endereco" placeholder="Endereço" v-model="endereco">
                            </div>
                            <div class="col-lg-12">
                                <input type="tel" required class="form-control" name="telefone" id="telefone" placeholder="Telefone" v-model="telefone">
                            </div>
                            <div class="col-lg-12">
                                <input type="text" required name="cpf" id="cpf" class="form-control" placeholder="CPF ou CREF" v-model="cpf">
                            </div>
                            <div class="col-lg-12">
                                <textarea class="form-control" required id="resumo" name="resumo" rows="3" placeholder="Resumo da proposta" v-model="resumo"></textarea>
                            </div>
                            <div class="col-lg-12">
                                <input type="file" required class="form-control" name="arquivo" id="arquivo" placeholder="Enviar arquivo" accept=".pdf">
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" required id="termos-de-uso" v-model="termos">
                                    <label class="form-check-label" for="termos-de-uso">
                                        Li e aceito os termos de 
                                        <a routerLink="/politica-de-privacidade">
                                            Política de privacidade
                                        </a>
                                        .
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <input type="submit" value="Enviar Proposta" class="btn-enviar">
                            </div>
                            <span v-if="enviando" :class="['alert', mensagem.tipo]">Enviando...</span>
                            <div v-if="enviando" class="alert">
                                <strong>Enviando...</strong>
                            </div>
                            <div v-if="mensagem" :class="['alert', mensagem.tipo]">
                                <strong>{{ mensagem.texto }}</strong>
                            </div>
                        </div>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';

const urlHOME = 'https://api.cref20.org.br/wp-json/cref/v1/home';
const urlEnviarEmail = 'https://api.cref20.org.br/wp-json/cref/v1/enviarEmail';
const CONTATOURL = 'https://api.cref20.org.br/wp-json/cref/v1/contato'
export default {
    name: 'SobreHomeVue',
    data() {
        return {
            sobreConteudos: [],
            principios: [],
            contato: [],
            enviando: false, 
            mensagem: null, 
        }
    },
    mounted() {
        axios.get(urlHOME).then((response) => {
            this.sobreConteudos = response.data[0].corpo.sobre_home.map((sobre) => ({
                imagem_sobre: sobre.imagem_sobre_home,
                titulo_sobre: sobre.titulo_sobre_home
            }));

            this.principios = response.data[0].corpo.principios_home.map((principio) => ({
                titulo_principio: principio.titulo_principios,
                subtitulo_principio: principio.subtitulo_principios
            }))
        });
        axios.get(CONTATOURL).then((response) => {
            this.contato = {
                informacoes: response.data[0].corpo.informacoes.map((info) => ({
                    telefone_atendimento: info.telefone_atendimento,
                    link_telefone: info.link_telefone,
                    email_atendimento: info.email_atendimento,
                    email_projetos: info.email_projetos,
                    endereco: info.endereco,
                    horario: info.horario_funcionamento
                }))
            }
        });
    },
    methods: {
        sendEmail() {
            const email = `
                <p>Uma nova proposta de projeto foi enviada pelo formulário do site!<p>
                <p>Os dados enviados foram:</p>
                <p><strong>Nome:</strong> ${document.getElementById('nome').value}</p>
                <p><strong>Endereço:</strong> ${document.getElementById('endereco').value}</p>
                <p><strong>Telefone:</strong> ${document.getElementById('telefone').value}</p>
                <p><strong>CPF/CREF:</strong> ${document.getElementById('cpf').value}</p>
                <p><strong>Resumo da proposta:</strong> ${document.getElementById('resumo').value}</p>
            `;
            this.enviando = true;
            this.mensagem = null;

            const formData = new FormData();
            formData.append('anexo', document.getElementById('arquivo').files[0]);
            formData.append('email', email);
            formData.append('destinatario', this.contato.informacoes[0].email_projetos);
            formData.append('assunto', 'Proposta de Projeto - Site CREF');

            console.log('=== Debug ===')
            console.log(this.contato.informacoes[0].email_projetos);
            console.log('=== Debug ===')

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            };
            
            axios.post(urlEnviarEmail, formData, config).then((response) => {
                // alert(response.data[0]);
                this.mensagem = { tipo: 'alert-success', texto: response.data[0] };
                // this.$refs.formProposta.reset();
                document.getElementById('formProposta').reset();
                    setTimeout(() => {
                    window.location.href = '/'; 
                }, 2000);
            // eslint-disable-next-line no-unused-vars
            }).catch((error) => {
                this.mensagem = { tipo: 'alert-danger', texto: "Erro ao enviar formulário" };
            })
            .finally(() => {
                this.enviando = false; 
            });
        }
    }
}

</script>

<style scoped>
.alert {
    margin-top: 16px;
    border-radius: 5px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}

.alert strong {
    font-weight: 500;
}
.col-lg-6 img {
    border-bottom-left-radius: 779px 435px;
    border-bottom-right-radius: 779px 435px;
    height: 584px;
    object-fit: cover;
}
.sobre-home {
    background-color: #12575F;
}
.sobre-home svg {
    width: 100%;
    margin-top: -2px;
}
.sobre-home .col-lg-6 {
    margin-top: 6rem;
}
.sobre-home .person-sobre {
    width: 100%;
    object-fit: contain;
}
.sobre-home .info-sb-home .titulo-sobre h3 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.sobre-home .info-sb-home .lista-principios {
    list-style-image: url(../../assets/imgs/icon-principios.png);
}
.sobre-home .info-sb-home .lista-principios li {
    margin: 15px 0;
}
.sobre-home .info-sb-home .lista-principios li .titulo-principio {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.sobre-home .info-sb-home .lista-principios li .subtitulo-principios {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.sobre-home .info-sb-home .btn-sobre {
    margin-top: 40px;
}
 .sobre-home .info-sb-home .btn-sobre a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #45A047;
    padding: 20px 25px;
    border-radius: 50px;
    border: 2px solid #448945;
}
.sobre-home form .col-lg-12 {
    margin-bottom: 1rem;
}
.sobre-home form .form-check-label {
    color: #fff;
}
.sobre-home form .btn-enviar {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #45A047;
    padding: 20px 25px;
    border-radius: 50px;
    border: 2px solid #448945;
}
@media (max-width: 820px) {
    .sobre-home {
        position: relative;
    }
}
@media (max-width: 765px) {
    svg {
        display: none;
	}
}
 
</style>