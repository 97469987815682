<template>
    <section class="instagram-section">
        <svg xmlns="http://www.w3.org/2000/svg" width="1366" height="216" viewBox="0 0 1366 216" fill="none" preserveAspectRatio="none">
            <path d="M1367.23 0.081543H0.104858V15.2718C444.13 275.844 888.154 287.529 1367.23 15.2718V0.081543Z" fill="#12575F"/>
        </svg>

        <NewsletterVue/>

        <div class="container">
            <div class="row">
                <div class="col-lg-12 d-flex align-items-center justify-content-between">
                    <div class="titulo-instagram">
                        <h2>
                            Acompanhe o nosso Instagram
                        </h2>
                    </div>
                    <div class="btn-instagram">
                        <a href="instagram" aria-label="Link Instagram">
                            Acessar
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid instafeed-install mt-4">
            <div class="galeria-instagram">
                <div class="row" id="instafeed">
                </div>
            </div>  
        </div>
    </section>
</template>

<script>
import NewsletterVue from '../Newsletter/Newsletter.vue';

// libs

import Instafeed from 'instafeed.js';

export default {
    name: 'InstagramVue',
    components: {
        NewsletterVue
    },
    mounted() {
        this.initInstafeed();
    },
    methods: {
        initInstafeed() {
            let feed = new Instafeed({
                accessToken: 'IGQWRPVmlBenhUQmdLQm9QSEEtTWZAwa0ZApbzdJNVB5eUZAFNkpnNEFhSldzVGtMSVdYQXNQMUtUbUhOT1R1RWtRUHMzUWZAxRUhOU0xDSXRmaWJBT3Vrc0tqd2ZAlOG83VENlMElINDVDSHp2aFI1OG1IcnJtcGhSZAHMZD',
                limit: 4,
                template: '<div class="col-lg-3"><a href="{{link}}" target="_blank"> <img src="{{image}}" alt="{{caption}}" width="100%" height="311" style="object-fit: cover" /> </a></div>'
            })
            feed.run();
        }
    }
}
</script>

<style scoped>
.instagram-section {
    background-color: #313131;
}
.instagram-section svg {
    width: 100%;
    margin-top: -2px;
}

.instagram-section .titulo-instagram h2 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.instagram-section .btn-instagram a {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #45A047;
    padding: 20px 25px;
    border-radius: 50px;
    border: 2px solid #448945;
}

@media (max-width: 765px) {
    svg {
        display: none;
    }
}
 
</style>